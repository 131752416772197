import React, { useEffect, useState } from 'react';
import PercentCard from '../../components/dashboard/percentCard';
import { Accordion, Card, Col, Container, Row, Spinner, useAccordionButton } from 'react-bootstrap';
import { getIFOZCompany, getIFOZGroupCompany, getIFOZSexCompany, getIPACompany, getIPAGroupCompany, getIPASexCompany as getIAPSexCompany } from '../../services/statistics/insights';
import { CustomNav, ListTable, StyledAsyncSelectFilter, StyledSelectFilter, StyledTd, StyledTdQualitativeAnswer, StyledText, StyledTh, StyledTr } from '../settings/styles';
import debounce from 'debounce-promise';
import { getGroupByCompany } from '../../services/group/group';
import SidebarResponsiveComp from '../../components/sideBar/siderbar-responsive';
import { StyledTitleSettings } from '../settings/styles';
import { ContainerPercentCard } from '../../components/dashboard/percentCard/styles';
import { ContainerSelectFilters, ContainerFilters, ContainerDashboard, StyledSelectMonth, ContainerCardMatrix, ContainerOverflowCharts } from './styles';
import { StyledCardHeader, StyledCardBody, StyledCardBodyOverflow, StyledRowOverflow } from '../../components/dashboard/attentionPoints/styles';
import HeaderManager from '../../components/headerManager';
import { useAuth } from '../../context/AuthContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import surveyConfigQuestions from '../../data/questions.json'
import Dimension from '../../components/dashboard/dimension';
import { getDissertationsByParameters, getIAPByParameters, getIEPByParameters, getIFCZByParameters, getIFOZByParameters } from '../../services/statistics/happinessReport';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import CustomPagination from '../../components/customPagination';
import { StyledCardTitle } from '../welcome/styles';
import MonthReportLineChart from '../../components/dashboard/lineChart';
import IconButton from '@mui/material/IconButton';
import { GroupBase, ValueContainerProps, components } from 'react-select';
import { CalendarViewDayOutlined, CheckBox, FilterList } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CorrelationMatrix from '../../components/dashboard/heatmap/heatMap';
import { DimensionFormattedName, DimensionID } from '../../enums/dimension';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import { Button, Checkbox, FormControl, InputAdornment, InputLabel, ListItemText, ListSubheader, MenuItem, OutlinedInput, Paper, Select } from '@mui/material';
import LineChartGender from '../../components/dashboard/lineChart/ChartGender';
import LineChartAge from '../../components/dashboard/lineChart/age/ChartAge';
import LineChartGroup from '../../components/dashboard/lineChart/group/ChartGroup';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { ptBR } from '@mui/x-date-pickers/locales';
import { ContainerDimensionName, StyledHeaderDimension, StyledTitle } from '../../components/dashboard/dimension/styles';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useToast } from '../../context/Toast';
import { DimensionIfozElement, FormattedDimensionResults, FormattedQuestionResults } from '../../interfaces/dimension.interface';
import { DissertationPageResults } from '../../interfaces/dissertation.interface';
import { getAllDimensionsIFOZ, getGroupsAllDimensionsIFOZ } from '../../services/statistics/statisticsTools';
import { LineChartSeriesInterface } from '../../interfaces/chartSeries.interface';
import { representationAgeRangeOptions, representationGenderOptions, representationOptionsValues } from '../../services/utils/representationOptions';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomAccordion from '../../components/accordion/accordion';
import { useMediaQuery } from 'react-responsive';
import { yearsToDays } from 'date-fns';

const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: ".9em",
                },
            },
        },
    },
});

type GeneralDataModel = {
    generalIap: number | null,
    generalIfoz: number | null,
    generalIep: number | null,
    generalIfcz: number | null,
    generalEpDimension: FormattedDimensionResults | null,
    generalEngDimension: FormattedDimensionResults | null,
    generalVitDimension: FormattedDimensionResults | null,
    generalRelDimension: FormattedDimensionResults | null,
    generalSigDimension: FormattedDimensionResults | null,
    generalReaDimension: FormattedDimensionResults | null
}

type FilteredDataModel = {
    filteredIap: number | null,
    filteredIfoz: number | null,
    filteredIep: number | null,
    filteredIfcz: number | null,
    filteredEpDimension: FormattedDimensionResults | null,
    filteredEngDimension: FormattedDimensionResults | null,
    filteredVitDimension: FormattedDimensionResults | null,
    filteredRelDimension: FormattedDimensionResults | null,
    filteredSigDimension: FormattedDimensionResults | null,
    filteredReaDimension: FormattedDimensionResults | null
}

const HappinessReport = () => {
    const { user, SignOut, saveRetryingFlagOnStorage, deleteRetryingFlagOnStorage } = useAuth()
    const { showToast } = useToast()

    const [loading, setLoading] = useState(true)
    const [loadingDissertationsData, setLoadingDissertationsData] = useState(true)
    const [loadingSexesData, setLoadingSexesData] = useState(true)
    const [loadingAgeRangesData, setLoadingAgeRangesData] = useState(true)
    const [loadingGroupsData, setLoadingGroupsData] = useState(true)
    const [datePickerIsOpened, setDatePickerIsOpened] = useState(false)
    const [dimensionsByFilters, setDimensionsByFilters] = useState([] as LineChartSeriesInterface[])
    
    const [genderSeries, setGenderSeries] = useState([] as LineChartSeriesInterface[])
    const [ageRangesSeries, setAgeRangesSeries] = useState([] as LineChartSeriesInterface[])
    const [groupsSeries, setGroupsSeries] = useState([] as LineChartSeriesInterface[])

    const [representationFilterHasChanged, setRepresentationFilterHasChanged] = useState(false)
    const [groupFilterHasChanged, setGroupFilterHasChanged] = useState(false)
    const [generalData, setGeneralData] = useState({
        generalIap: null,
        generalIfoz: null,
        generalIep: null,
        generalIfcz: null,
        generalEpDimension: null,
        generalEngDimension: null,
        generalVitDimension: null,
        generalRelDimension: null,
        generalSigDimension: null,
        generalReaDimension: null
    } as GeneralDataModel)
    const [filteredData, setFilteredData] = useState({
        filteredIap: null,
        filteredIfoz: null,
        filteredIep: null,
        filteredIfcz: null,
        filteredEpDimension: null,
        filteredEngDimension: null,
        filteredVitDimension: null,
        filteredRelDimension: null,
        filteredSigDimension: null,
        filteredReaDimension: null
    } as FilteredDataModel)

    const [dissertationPageResults, setDissertationPageResults] = useState({
        total_items: 0,
        total_pages: 0,
        current_page: 1,
        data: []
    } as DissertationPageResults)

    const [dateValue, setDateValue] = useState<any>('');

    const renderDate = (date: string) => {
        if (!date) return ''
        const dateObj = new Date(date);
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };

        const formattedDate = dateObj.toLocaleDateString('pt-BR', options);

        return formattedDate;
    }

    const loadDissertations = async (page: number, year?: string, month?: string, filters?: any) => {
        setLoadingDissertationsData(true);
        const company = user.user?.company_id

        if (!company)
            throw new Error("company_not_found")

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear().toString();
        const currentMonth = (currentDate.getMonth() + (currentDate.getDate() < 20 ? 0 : 1)).toString().padStart(2, '0');

        const yearToUse = year || currentYear;
        const monthToUse = month || currentMonth;
        try {

            let data = await getDissertationsByParameters(company, monthToUse, yearToUse, `${page}`, filters)

            setDissertationPageResults({
                total_items: data.total_items,
                total_pages: data.total_pages,
                current_page: parseInt(data.current_page),
                data: data.data ?? []
            });
            setLoadingDissertationsData(false);
        } catch (e) {
            setLoadingDissertationsData(false);
            throw e;
        }
    }

    const [selectGroupOptions, setSelectGroupOptions] = useState<object[]>([]);

    const loadGroupInputOptions = async (term?: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                let options: any = []
                const company = user.user?.company_id

                if (!company)
                    throw new Error("company_not_found")

                let pagesToLoad = 6; // TODO: Implementar lógica de consultar quantas páginas de grupos a organização possui
                for (let i = 1; i <= pagesToLoad; i++) {
                    let companyGroupList: any = await getGroupByCompany({ company_id: company }, i) // TODO: Implementar lógica de percorrer todas as páginas de grupos

                    if (!companyGroupList || companyGroupList?.data?.length <= 0)
                        return resolve(options)

                    companyGroupList?.data?.forEach((element: any) => {
                        options.push({
                            label: element?.name,
                            value: element.id
                        })
                    })
                }

                options = options.sort((a: any, b: any) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });

                return resolve(options)

            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    useEffect(() => {
        let currentDate = new Date();
        if (currentDate.getDate() < 20)
            currentDate.setMonth(currentDate.getMonth() - 1)
        const formattedDate = currentDate.toISOString().slice(0, 7);
        setDateValue(formattedDate);

        const fetchData = async () => {
            try {
                // Carrega as opções dentro do Select de Filtro por Grupos
                let groupInputOptions = await loadGroupInputOptions() as object[];
                setSelectGroupOptions(groupInputOptions);

                // Depois que as opções do grupo forem carregadas, você pode chamar a função de carga de dados
                loadData();
            } catch (error: any) {
                throw error
            }
        };
        fetchData();
    }, []);

    

    // const getSelectedDate = (): { yearToUse: string, monthToUse: string } => {
    //     const currentDate = new Date();
    //     const currentYear = currentDate.getFullYear().toString();
    //     const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    //     const [year, month] = dateValue.split('-')
    //     const yearToUse = year || currentYear;
    //     const monthToUse = month || currentMonth;

    //     return { yearToUse: yearToUse, monthToUse: monthToUse};
    // }

    // Limpa os resultados obtidos a partir do filtro no loadFilteredData
    const clearFilteredData = () => {
        setFilteredData({
            filteredIap: null,
            filteredIfoz: null,
            filteredIep: null,
            filteredIfcz: null,
            filteredEpDimension: null,
            filteredEngDimension: null,
            filteredVitDimension: null,
            filteredRelDimension: null,
            filteredSigDimension: null,
            filteredReaDimension: null
        });
    }

    const fetchAllAvailableSexesData = async ({companyId, monthToUse, yearToUse}:any) => {
        setLoadingSexesData(true);
        let filteredDimensionIfozListFromSexFilters: LineChartSeriesInterface[] = [];

        // Carrega todos os gêneros/sexos disponíveis para filtrar com base no valor que o Back-end consegue interpretar a partir do enum representationOtions
        let availableSexes = representationGenderOptions
            .map(key => representationOptionsValues[key])
            .filter(value => typeof value === 'number');

        for (let sexFilter of availableSexes) {

            let filterLabel: string | undefined;

            // Procura na lista de opções de Representatividade pela label do sexFilter (que é um número que o back-end consegue interpretar)
            // Começa percorrendo a lista de opções de Representatividade
            for (const representationOptionLabel in representationOptionsValues) {
                // Pega o valor numérico da opção de Representatividade
                const representationOptionValue = representationOptionsValues[representationOptionLabel];

                // Verifica se esse valor numérico é igual ao filtro que está sendo iterado
                if (representationOptionValue === sexFilter) {
                    // Em caso positivo, significa que foi encontrado uma opção para o valor numérico solicitado
                    filterLabel = representationOptionLabel;
                    break;
                }
            }

            // Carrega o IFOZ de todas as dimensões com base no filtro de sexo da iteração atual e com os demais filtros caso existam
            let dimensionIfozFilteredBySex = await getAllDimensionsIFOZ(companyId, monthToUse, yearToUse, 
                { sexes: [sexFilter] }) as any[];

            filteredDimensionIfozListFromSexFilters.push({
                label: `${filterLabel}`,
                data: dimensionIfozFilteredBySex
            });
        }
        setGenderSeries(filteredDimensionIfozListFromSexFilters);
        setLoadingSexesData(false);
    }

    const fetchAllAvailableAgeRangesData = async ({companyId, monthToUse, yearToUse}:any) => {
        setLoadingAgeRangesData(true);
        let filteredDimensionIfozListFromAgeRangeFilters: LineChartSeriesInterface[] = [];

        // Carrega todos as faixas etárias disponíveis para filtrar com base no valor que o Back-end consegue interpretar a partir do enum representationOtions
        let availableAgeRanges: number[][] = representationAgeRangeOptions
            .map(key => representationOptionsValues[key])
            .filter(value => typeof value === 'object') as number[][];

        for (let ageRangeFilter of availableAgeRanges) {

            let filterLabel: string | undefined;

            // Procura na lista de opções de Representatividade pela label do ageRangeFilter (que é um número que o back-end consegue interpretar)
            // Começa percorrendo a lista de opções de Representatividade
            for (const representationOptionLabel in representationOptionsValues) {
                // Pega o valor numérico da opção de Representatividade
                const representationOptionValue = representationOptionsValues[representationOptionLabel];

                // Verifica se esse valor numérico é igual ao filtro que está sendo iterado
                if (representationOptionValue === ageRangeFilter) {
                    // Em caso positivo, significa que foi encontrado uma opção para o valor numérico solicitado
                    filterLabel = representationOptionLabel;
                    break;
                }
            }

            // Carrega o IFOZ de todas as dimensões com base no filtro de faixa etária da iteração atual e com os demais filtros caso existam
            let dimensionIfozFilteredByAgeRange = await getAllDimensionsIFOZ(companyId, monthToUse, yearToUse, 
                { ageRanges: [ageRangeFilter] }) as any[];

            filteredDimensionIfozListFromAgeRangeFilters.push({
                label: `${filterLabel}`,
                data: dimensionIfozFilteredByAgeRange
            });
        }
        setAgeRangesSeries(filteredDimensionIfozListFromAgeRangeFilters);
        setLoadingAgeRangesData(false);
    }

    const fetchFirstAvailableGroupsData = async ({companyId, monthToUse, yearToUse}:any) => {
        setLoadingGroupsData(true);
        let filteredDimensionIfozListFromGroupsFilters: LineChartSeriesInterface[] = [];

        let groupList = await loadGroupInputOptions() as object[];

        // let groupsToFetchData = groupList.length >= 10 ? 10 : groupList.length;
        let groupsToFetchData = groupList.length;


        let groupIdList = groupList.map((group: any) => group?.value);

        let groupDimensionResultsList = await getGroupsAllDimensionsIFOZ(companyId, monthToUse, yearToUse, groupIdList);

        Object.keys(groupDimensionResultsList).forEach((groupId: string) => {
            let dimensionIfozFilteredByGroup = groupDimensionResultsList[groupId];
            
            if(dimensionIfozFilteredByGroup.length > 0 && dimensionIfozFilteredByGroup[0]?.totalIfoz) {
                filteredDimensionIfozListFromGroupsFilters.push({
                    label: `${(groupList.find((item: any) => item?.value == groupId) as any)?.label ?? "-"}`,
                    data: dimensionIfozFilteredByGroup
                });
            }
        });

        setGroupsSeries(filteredDimensionIfozListFromGroupsFilters);
        setLoadingGroupsData(false);
    }

    
    const loadData = async (year?: string, month?: string) => {
        try {
            setLoading(true)
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear().toString();
            const currentMonth = (currentDate.getMonth() + (currentDate.getDate() < 20 ? 0 : 1)).toString().padStart(2, '0');

            const yearToUse = year || currentYear;
            const monthToUse = month || currentMonth;

            const companyId = user.user?.company_id
            if (!companyId)
                throw new Error("company_not_found")

            const [generalIfoz, generalIap, generalIep, generalIfcz, generalDimensionsIfozList] = await Promise.all([
                getIFOZByParameters(companyId, monthToUse, yearToUse),
                getIAPByParameters(companyId, monthToUse, yearToUse),
                getIEPByParameters(companyId, monthToUse, yearToUse),
                getIFCZByParameters(companyId, monthToUse, yearToUse),
                getAllDimensionsIFOZ(companyId, monthToUse, yearToUse)
            ]);
            setGeneralData({
                generalIap: typeof generalIap === 'number' ? generalIap : null,
                generalIfoz: generalIfoz?.ifoz ?? null,
                generalIep: typeof generalIep === 'number' ? generalIep : null,
                generalIfcz: typeof generalIfcz === 'number' ? generalIfcz : null,
                generalEpDimension: generalDimensionsIfozList[DimensionID.POSITIVE_EMOTIONS - 1] ?? [],
                generalEngDimension: generalDimensionsIfozList[DimensionID.ENGAGEMENT - 1] ?? [],
                generalRelDimension: generalDimensionsIfozList[DimensionID.RELATIONSHIP - 1] ?? [],
                generalSigDimension: generalDimensionsIfozList[DimensionID.MEANING - 1] ?? [],
                generalReaDimension: generalDimensionsIfozList[DimensionID.ACCOMPLISHMENT - 1] ?? [],
                generalVitDimension: generalDimensionsIfozList[DimensionID.VITALITY - 1] ?? []
            });
            let groupInputOptions = await loadGroupInputOptions() as object[];
            setSelectGroupOptions(groupInputOptions)

            await loadDissertations(1, yearToUse, monthToUse);
            try {
                const dataToSend = {
                    companyId,
                    monthToUse,
                    yearToUse
                }
                // Carregamento do ifoz por dimensão de CADA Segmentação
                // Carregamendo de cada filtro por Sexo/Gênero disponível ou todas as opções disponíveis caso não haja filtro
                await fetchAllAvailableSexesData(dataToSend);
                // Carregamendo de cada filtro por Faixa Etária disponível
                await fetchAllAvailableAgeRangesData(dataToSend);

                // Carregamendo de cada filtro por Grupos disponível
                await fetchFirstAvailableGroupsData(dataToSend);

                
            } catch (error: any) {
                setLoadingSexesData(false)
                setLoadingAgeRangesData(false);
                setLoadingGroupsData(false);
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                throw error
            }
            setLoading(false);

        } catch (error: any) {
            setLoading(false)
            console.log(error)
            if (error?.response?.status === 401) {
                showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                setTimeout( () => {
                    showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                    setTimeout( () => {                                
                        SignOut('/admin/signup');
                    }, 5000);
                }, 2500);

            } else if (error?.response?.status === 403) {
                showToast('Ops! Parece que você não tem permissão para isso :(\n\nVerifique se você está utilizando a conta correta. Caso precise de mais informações, entre em contato com nosso suporte clicando no botão de "Ajuda" na parte inferior da sua tela.', 'error', 30000)

            } else if (error?.response?.data?.error) {
                switch (error.response.data.error) {
                    case "Erro ao validar o token - zenbox_backend": // TODO: Verificar com o back-end casos onde o token não venceu ainda porém está tendo esse retorno
                        try {
                            const retryingFlag = localStorage.getItem('zenbox.retryingFlag')
                            if (retryingFlag && JSON.parse(retryingFlag) && (JSON.parse(retryingFlag)).isRetrying == true) {
                                throw Error("Erro ao vlaidar o token após múltiplas tentativas.");
                            } else {
                                saveRetryingFlagOnStorage({isRetrying: true});

                                const currentDate = new Date();
                                const currentYear = currentDate.getFullYear().toString();
                                const currentMonth = (currentDate.getMonth() + (currentDate.getDate() < 20 ? 0 : 1)).toString().padStart(2, '0');

                                const yearToUse = year || currentYear;
                                const monthToUse = month || currentMonth;

                                loadData(yearToUse, monthToUse);
                            }
                            deleteRetryingFlagOnStorage();
                        } catch(e: any) {
                            showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                            setTimeout( () => {
                                showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                                setTimeout( () => {                                
                                    SignOut('/admin/signup');
                                }, 5000);
                            }, 2500);
                        }
                        break;
                    default:
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                        throw error;
                }
            } else {
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                throw error
            }
        }
    }

    const loadDataByDate = async (date: any) => {
        setDateValue(date); //TODO: verificar possibilidade de retorno do erro 404 em loadData
        // Carrega os dados padrões quando não há uma data definida
        if (!date) {
            loadData()

        } else {
            // Aqui nesse bloco de código, existe data selecionada
            const [year, month] = date.split('-');
            let representationsToFilter = representationSelectedOptions;
            let groupsToFilter = selectedGroupOptions;
            // Caso não haja nenhum filtro por representatividade nem por agrupamento, é solicitado o carregamento sem filtros com a data informada
            if (!(representationsToFilter?.length > 0) && !(groupsToFilter?.length > 0)) {
                loadData(year, month);
                clearFilteredData();

            } else { // Se houver filtros, será solicitado o carregamento dos dados, tanto os gerais quanto os com filtros já selecionados e com base na data informada
                // loadData(year, month); esse loadData esta sobrepondo as requisiçoes
                 loadFilteredData(year, month);
            }
        }
    }    

    const fetchFilteredSexesData = async ({companyId, monthToUse, yearToUse, filters}:any) => {
        setLoadingSexesData(true);
        // Carregamendo de cada filtro por Sexo/Gênero selecionado ou todas as opções disponíveis caso não haja filtro
        let filteredDimensionIfozListFromSexFilters: LineChartSeriesInterface[] = [];

        // Carrega todos os gêneros/sexos disponíveis para filtrar com base no valor que o Back-end consegue interpretar a partir do enum representationOtions
        let availableSexes = representationGenderOptions
            .map(key => representationOptionsValues[key])
            .filter(value => typeof value === 'number');

        let sexesToLoad: number[] = ((filters.sexes.length > 0) ? filters.sexes : availableSexes) as number[];
        for (let sexFilter of sexesToLoad) {

            let filterLabel: string | undefined;

            // Procura na lista de opções de Representatividade pela label do sexFilter (que é um número que o back-end consegue interpretar)
            // Começa percorrendo a lista de opções de Representatividade
            for (const representationOptionLabel in representationOptionsValues) {
                // Pega o valor numérico da opção de Representatividade
                const representationOptionValue = representationOptionsValues[representationOptionLabel];

                // Verifica se esse valor numérico é igual ao filtro que está sendo iterado
                if (representationOptionValue === sexFilter) {
                    // Em caso positivo, significa que foi encontrado uma opção para o valor numérico solicitado
                    filterLabel = representationOptionLabel;
                    break;
                }
            }

            // Carrega o IFOZ de todas as dimensões com base no filtro de sexo da iteração atual e com os demais filtros caso existam
            let dimensionIfozFilteredBySex = await getAllDimensionsIFOZ(companyId, monthToUse, yearToUse, 
                { sexes: [sexFilter], ageRanges: filters.ageRanges, groups: filters.groups }) as any[];

            filteredDimensionIfozListFromSexFilters.push({
                label: `${filterLabel} (Filtrado)`,
                data: dimensionIfozFilteredBySex
            });
        }
        setGenderSeries(filteredDimensionIfozListFromSexFilters);
        setLoadingSexesData(false);
    }

    const fetchFilteredAgeRangesData = async ({companyId, monthToUse, yearToUse, filters}:any) => {
        setLoadingAgeRangesData(true);
        // Carregamendo de cada filtro por Faixa Etária selecionada ou todas as opções disponíveis caso não haja filtro
        let filteredDimensionIfozListFromAgeRangeFilters: LineChartSeriesInterface[] = [];

        // Carrega todos as faixas etárias disponíveis para filtrar com base no valor que o Back-end consegue interpretar a partir do enum representationOtions
        let availableAgeRanges = representationAgeRangeOptions
            .map(key => representationOptionsValues[key])
            .filter(value => typeof value === 'object');

        let ageRangesToLoad: number[][] = ((filters.ageRanges.length > 0) ? filters.ageRanges : availableAgeRanges) as number[][];
        for (let ageRangeFilter of ageRangesToLoad) {

            let filterLabel: string | undefined;

            // Procura na lista de opções de Representatividade pela label do ageRangeFilter (que é um número que o back-end consegue interpretar)
            // Começa percorrendo a lista de opções de Representatividade
            for (const representationOptionLabel in representationOptionsValues) {
                // Pega o valor numérico da opção de Representatividade
                const representationOptionValue = representationOptionsValues[representationOptionLabel];

                // Verifica se esse valor numérico é igual ao filtro que está sendo iterado
                if (representationOptionValue === ageRangeFilter) {
                    // Em caso positivo, significa que foi encontrado uma opção para o valor numérico solicitado
                    filterLabel = representationOptionLabel;
                    break;
                }
            }

            // Carrega o IFOZ de todas as dimensões com base no filtro de faixa etária da iteração atual e com os demais filtros caso existam
            let dimensionIfozFilteredByAgeRange = await getAllDimensionsIFOZ(companyId, monthToUse, yearToUse, 
                { ageRanges: [ageRangeFilter], sexes: filters.sexes, groups: filters.groups }) as any[];

            filteredDimensionIfozListFromAgeRangeFilters.push({
                label: `${filterLabel} (Filtrado)`,
                data: dimensionIfozFilteredByAgeRange
            });
        }
        setAgeRangesSeries(filteredDimensionIfozListFromAgeRangeFilters);
        setLoadingAgeRangesData(false);
    }

    const fetchFilteredGroupsData = async ({companyId, monthToUse, yearToUse, filters}:any) => {
        setLoadingGroupsData(true);
        // Carregamendo de cada filtro por Grupos selecionados
        let filteredDimensionIfozListFromGroupsFilters: LineChartSeriesInterface[] = [];

        // TODO: Implementar lógica de consultar todos os grupos para fazer o operador ternário aqui entre grupos selecionados e todos os grupos
        let groupsToLoad: string[] = ((filters.groups.length > 0) ? filters.groups : selectGroupOptions.map((item: any) => item?.value)) as string[];
        for (let groupFilter of groupsToLoad) {

            let filterLabel = (selectGroupOptions.find((option: any) => option.value == groupFilter) as any)?.label as string ?? ''

            let dimensionIfozFilteredByGroup = await getAllDimensionsIFOZ(companyId, monthToUse, yearToUse, 
                { groups: [groupFilter], sexes: filters.sexes, ageRanges: filters.ageRanges }) as any[];

            filteredDimensionIfozListFromGroupsFilters.push({
                label: `${filterLabel} (Filtrado)`,
                data: dimensionIfozFilteredByGroup
            });
        }
        setGroupsSeries(filteredDimensionIfozListFromGroupsFilters);
        setLoadingGroupsData(false);
    }

    // Carrega os dados com base em filtros selecionados nos inputs de Representatividade e Grupos, tendo como carregamento padrão a data atual selecionada
    const loadFilteredData = async (year?: string, month?: string) => {
        let representationsToFilter = representationSelectedOptions;
        let groupsToFilter = selectedGroupOptions;
        let thereIsSomeFilter = (representationsToFilter?.length > 0) || (groupsToFilter?.length > 0)

        // Caso não haja nenhum filtro por representatividade nem por agrupamento, é solicitado o carregamento padrão
        if (!thereIsSomeFilter) {
            setDimensionsByFilters([]);
            setSelectedGroups([]);
            clearFilteredData();
            loadData()
            return;
        }
        // Aqui rodamos quando há pelo menos um filtro selecionado, seja ele por representatividade ou por agrupamento
        try {
            setLoading(true);

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear().toString();
            const currentMonth = (currentDate.getMonth() + (currentDate.getDate() < 20 ? 0 : 1)).toString().padStart(2, '0');

            const yearToUse = year || currentYear;
            const monthToUse = month || currentMonth;


            // Carrega o ID da empresa do usuário e caso seja nulo um erro é lançado
            const companyId = user.user?.company_id
            if (!companyId)
                throw new Error("company_not_found")


            // Carrega os gêneros/sexos escolhidos no select de Filtro por Representatividade, mapeando pela label o valor numérico no molde que o back-end interpretará
            let selectedSexes = representationsToFilter
                .map(key => representationOptionsValues[key])
                .filter(value => typeof value === 'number');

            // Carrega as Faixas Etárias escolhidos no select de Filtro por Representatividade, mapeando pela label o valor numérico no molde que o back-end interpretará
            let selectedAgeRanges = representationsToFilter
                .map(key => representationOptionsValues[key])
                .filter(value => typeof value === 'object')

            // Carrega as escolhas de Sexos/Gêneros e Faixas Etárias já mapeadas para os valores no molde que o back-end interpretará somando aos IDs dos grupos escolhidos
            let filters = {
                sexes: selectedSexes,
                ageRanges: selectedAgeRanges,
                groups: selectedGroupOptions
            }

            // Carrega o IFOZ Geral passando os filtros selecionados
            // Carrega o IAP Geral passando os filtros selecionados
            // Carrega o IEP Geral passando os filtros selecionados
            // Carrega o IFCZ Geral passando os filtros selecionados
            // Carrega o IFOZ de cada uma das 6 dimensões passando os filtros selecionados
            const [
                filteredIFOZ,
                filteredIAP,
                filteredIEP,
                filteredIFCZ,
                filteredDimensionIfozList
            ] = await Promise.all([
                getIFOZByParameters(companyId, monthToUse, yearToUse, filters),
                getIAPByParameters(companyId, monthToUse, yearToUse, filters),
                getIEPByParameters(companyId, monthToUse, yearToUse, filters),
                getIFCZByParameters(companyId, monthToUse, yearToUse, filters),
                getAllDimensionsIFOZ(companyId, monthToUse, yearToUse, filters)
            ]);

            setFilteredData({
                ...filteredData,
                filteredIfoz: filteredIFOZ?.ifoz ?? null,
                filteredIap: typeof filteredIAP === 'number' ? filteredIAP : null,
                filteredIep: typeof filteredIEP === 'number' ? filteredIEP : null,
                filteredIfcz: typeof filteredIFCZ === 'number' ? filteredIFCZ : null,
                filteredEpDimension: filteredDimensionIfozList[DimensionID.POSITIVE_EMOTIONS - 1] ?? [],
                filteredEngDimension: filteredDimensionIfozList[DimensionID.ENGAGEMENT - 1] ?? [],
                filteredRelDimension: filteredDimensionIfozList[DimensionID.RELATIONSHIP - 1] ?? [],
                filteredSigDimension: filteredDimensionIfozList[DimensionID.MEANING - 1] ?? [],
                filteredReaDimension: filteredDimensionIfozList[DimensionID.ACCOMPLISHMENT - 1] ?? [],
                filteredVitDimension: filteredDimensionIfozList[DimensionID.VITALITY - 1] ?? []
            });


            await loadDissertations(1, yearToUse, monthToUse, filters);

            try {
                const dataToSend = {
                    companyId,
                    monthToUse,
                    yearToUse,
                    filters
                }
                await fetchFilteredSexesData(dataToSend);
                await fetchFilteredAgeRangesData(dataToSend);
                await fetchFilteredGroupsData(dataToSend);
                
            } catch (error: any) {
                setLoadingSexesData(false)
                setLoadingAgeRangesData(false);
                setLoadingGroupsData(false);
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                throw error
            }
            setLoading(false);

        } catch (error: any) {
            setLoading(false)
            console.log(error)
            if (error?.response?.status === 401) {
                showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                setTimeout( () => {
                    showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                    setTimeout( () => {                                
                        SignOut('/admin/signup');
                    }, 5000);
                }, 2500);

            } else if (error?.response?.status === 403) {
                showToast('Ops! Parece que você não tem permissão para isso :(\n\nVerifique se você está utilizando a conta correta. Caso precise de mais informações, entre em contato com nosso suporte clicando no botão de "Ajuda" na parte inferior da sua tela.', 'error', 30000)

            } else if (error?.response?.data?.error) {
                switch (error.response.data.error) {
                    case "Erro ao validar o token - zenbox_backend":
                        try {
                            const retryingFlag = localStorage.getItem('zenbox.retryingFlag')
                            if (retryingFlag && JSON.parse(retryingFlag) && (JSON.parse(retryingFlag)).isRetrying == true) {
                                throw Error("Erro ao vlaidar o token após múltiplas tentativas.");
                            } else {
                                saveRetryingFlagOnStorage({isRetrying: true});

                                const currentDate = new Date();
                                const currentYear = currentDate.getFullYear().toString();
                                const currentMonth = (currentDate.getMonth() + (currentDate.getDate() < 20 ? 0 : 1)).toString().padStart(2, '0');

                                const yearToUse = year || currentYear;
                                const monthToUse = month || currentMonth;

                                loadFilteredData(yearToUse, monthToUse);
                            }
                            deleteRetryingFlagOnStorage();
                        } catch(e: any) {
                            showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                            setTimeout( () => {
                                showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                                setTimeout( () => {                                
                                    SignOut('/admin/signup');
                                }, 5000);
                            }, 2500);
                        }
                        break;

                    default:
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                        throw error;
                }
            } else {
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                throw error
            }
        }
    }



    // FILTRO DE REPRESENTATIVIDADE
    // Variável que vai armazenar em uma array de strings as opções que irão ser exibidas dentro do select de representatividade
    const [representationSelectedOptions, setRepresentationSelectedOptions] = useState<string[]>([]);

    // Handler que monitora qualquer alteração no valor do select de representatividade
    const handleChangeRepresentation = (selectedOption: any) => {
        const { target: { value } } = selectedOption;

        setRepresentationSelectedOptions(typeof value === 'string' ? value.split(',') : value);
        setRepresentationFilterHasChanged(true);
    };

    const handleOpenRepresentationSelect = (event: any) => {
        // Retira o foco da primeira opção do select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }            
          }, 0);
    }

    // Handler que monitora quando o usuário fecha o select de representatividade após tê-lo aberto
    const handleCloseRepresentationSelect = (event: any) => {
        // Retira o foco do campo de select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }            
          }, 0);
        if (representationFilterHasChanged) {
            loadFilteredData(dateValue.split('-')[0], dateValue.split('-')[1]);
            setRepresentationFilterHasChanged(false);
        }
    }
    // FILTRO DE REPRESENTATIVIDADE



    // FILTRO DE GRUPO
    const groupOptions = [
        'Agrupamento 1',
        'Agrupamento 2',
        'Agrupamento 3',
    ]

    const [selectedGroupOptions, setSelectedGroups] = useState<string[]>([]);
    const handleChangeGroup = (selectedGroupInputOptions: any) => {
        const { target: { value } } = selectedGroupInputOptions;

        setSelectedGroups(typeof value === 'string' ? value.split(',') : value)
        setGroupFilterHasChanged(true);
    };

    const handleOpenGroupSelect = (event: any) => {
        // Retira o foco da primeira opção do select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }            
          }, 0);
    }

    const handleCloseGroupSelect = async(event: any) => {
        // Retira o foco do campo de select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }            
        }, 0);

        if (groupFilterHasChanged) {
            loadFilteredData(dateValue.split('-')[0], dateValue.split('-')[1]);
            setGroupFilterHasChanged(false);
        }
    }
    // FILTRO DE GRUPO

   

   
    //PARA O USO DAS SETAS AO LADO DO DATA PICKER

        const handleNextMonth = async() => { // Função para avançar um mês, coloca no onclick do botão com seta
            const formattedDate = dayjs(dateValue).add(1, 'month')?.toISOString().slice(0, 7);
            await loadDataByDate(formattedDate);
        };

        const handlePreviousMonth = async() => {            
            const formattedDate = dayjs(dateValue).subtract(1, 'month')?.toISOString().slice(0, 7);
            await loadDataByDate(formattedDate);
        };

    //PARA O USO DAS SETAS AO LADO DO DATA PICKER

    //ACCORDION RESPOSTAS QUALITATIVAS
    const [cardClicked, setCardClicked] = useState(false);
    function CustomToggle({ children, eventKey }: any) {
        const decoratedOnClick = useAccordionButton(eventKey, () =>
            setCardClicked(!cardClicked)
        );

        return (
            <button
                type="button"
                style={{
                    background: 'transparent',
                    border: 'none',
                    color: 'transparent',
                    outline: 'none',
                    width: '100%',
                    padding: '0',
                }}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const [isOpenGenderGraph, setIsOpenGenderGraph] = useState(false);
    const [isOpenAgeRangeGraph, setIsOpenAgeRangeGraph] = useState(false);
    const [isOpenGroupGraph, setIsOpenGroupGraph] = useState(false);

    

    return (
        <>
            <SidebarResponsiveComp title='Relatório Mensal' />
            <HeaderManager title='Relatório Mensal' />
            <CustomNav className="mt-1" style={{ margin: '0', border: 'none' }}>
            </CustomNav>

            <Container fluid className='d-flex flex-column' >
                <ContainerSelectFilters>
                    <Col>
                        <div style={{display: 'flex', alignItems: 'center', gap:'1em'}}>   


                            <div style={{display: 'flex', gap:'.5em', minHeight: '56px'}}>
                                <IconButton disabled={loading} onClick={handlePreviousMonth} sx={{ height: '100%', background:'#FFFFFF', borderRadius:'.2em', minHeight: '56px',  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',}} >
                                    <ChevronLeftIcon sx={{ fontSize: 35 }} />
                                </IconButton>

                                <IconButton disabled={loading} onClick={handleNextMonth} sx={{ height: '100%', background:'#FFFFFF', borderRadius:'.2em', minHeight: '56px',  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',}} >
                                    <ChevronRightIcon sx={{ fontSize: 35 }} /> 
                                </IconButton>   
                            </div>
                        
                            {/* <StyledSelectMonth 
                                type="month"
                                value={dateValue}
                                // components={{ ValueContainer }}
                                onChange={async (e) => {
                                    await loadDataByDate(e.target.value)
                                }}
                            /> */}
                            <FormControl disabled sx={{ background: '#FFF', minHeight: '52.16px', borderRadius: '6px', borderColor:'transparent',
                                width: '100%', // width para telas pequenas
                                // [theme.breakpoints.up('lg')]: {
                                //   width: '34%', // width para telas médias e maiores
                                // },
                                // [theme.breakpoints.up('sm')]: {
                                //   width: '45%', // width para telas grandes e maiores
                                // },
                                // [theme.breakpoints.down('xs')]: {
                                //     width: '100%', // width para telas grandes e maiores
                                // },

                                '@media (max-width:694px)': {
                                    width: '100%',
                                    fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                },  

                                '@media (min-width:695px) and (max-width: 1200px)': {
                                    width: '50%',
                                    fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                },  

                                '@media (min-width:1201px) and (max-width: 1440px)': {
                                    width: '34%',
                                    fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                },  

                                '@media (min-width:1441px)': {
                                    width: '34%',
                                    fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                },  


                             }}>
                                <DatePicker
                                    sx={{
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'transparent', // Cor da borda
                                            borderRadius:'6px',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)', 

                                        },
                                        '&:focus-visible .MuiOutlinedInput-notchedOutline': {
                                            outline: 'transparent', // Isso removerá o outline
                                        },
                                        '&.Mui-focused': {
                                            fontWeight: 'bold',
                                            border: 'transparent', // Isso removerá o outline
                                        },
                                        '&.MuiInputLabel-outlined': { // Estilo padrão
                                            fontWeight: 'bold',
                                          
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            cursor: 'pointer'
                                        },
                                        '& .MuiInputBase-input': {
                                            cursor: 'pointer'
                                        },
                                                                                  
                                    }}

                                    open={datePickerIsOpened}
                                    disabled={loading}
                                    onClose={() => setDatePickerIsOpened(false)}
                                    slotProps={{
                                        textField: {
                                            onClick: () => setDatePickerIsOpened(true),             
                                        },
                                        field: {
                                            readOnly: true
                                        },
                                        layout: {
                                            sx: {
                                                [`.MuiPickersYear-root`]: {
                                                    flexBasis: '100%',
                                                    width: '100%',

                                            },
                                            [`.MuiPickersYear-yearButton`]: {
                                                fontSize: '1.65em',
                                                margin: '.5em 0',
                                                lineHeight: '0',
                                                width: '45%',
                                                height: '2.5em',
                                                borderRadius: '2em',
                                                
                                            },
                                            
                                            [`.MuiPickersMonth-monthButton::after`]:{
                                                content: '"."',
                                                position: 'absolute',

                                            },
                                          },
                                        },
                                    }}

                                    format='MMMM/YYYY'
                                    yearsPerRow={3}
                                    timezone='America/Sao_Paulo'
                                    views={["month", "year"]}
                                    value={dateValue ? dayjs(dateValue) : null}
                                    onAccept={async (e) => {
                                        const formattedDate = e?.toISOString().slice(0, 7);
                                        await loadDataByDate(formattedDate);
                                    }}
                                />
                            </FormControl>
                        </div>
                    </Col>







                    <ContainerFilters>
                        <FormControl sx={{ width: '100%', background: '#FFF', minHeight: '52.16px', borderRadius: '6px' }}>
                            <InputLabel id="multiple-select">Filtrar por representatividade</InputLabel>
                            <Select

                                labelId="multiple-select"
                                id="multiple-select"
                                multiple
                                disabled={loading}
                                value={representationSelectedOptions}
                                onChange={handleChangeRepresentation}
                                onOpen={handleOpenRepresentationSelect}
                                IconComponent={FilterListIcon}
                                onClose={handleCloseRepresentationSelect}
                                input={<OutlinedInput label="Filtrar por representatividade"
                                    color='primary'
                                    sx={{
                                        color: '#3C4043', //cor do texto dentro do input
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: representationSelectedOptions.length > 0 ? '#c9c9c9' : 'transparent', // Cor da borda
                                            borderRadius: '6px',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9',

                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor:  '#c9c9c9' , // Cor da borda ao passar o mouse
                                        },
                                        '.MuiSelect-iconOpen': {
                                            transform: 'none !important',
                                        },

                                        '@media (max-width:601px)': {
                                            width: '100%',
                                            fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                        },
                                    }}

                                />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                <ListSubheader>Por Gênero</ListSubheader>
                                {representationGenderOptions.map((name: string) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={representationSelectedOptions.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                        <Button
                                            sx={{
                                            display: representationSelectedOptions.indexOf(name) > -1 ? 'block' : 'none',
                                            }}
                                            variant="outlined"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRepresentationSelectedOptions([name]);
                                                handleChangeRepresentation({target: { value: [name] }});
                                            }}
                                        >Somente</Button>
                                    </MenuItem>
                                ))}

                                <ListSubheader>Por Faixa Etária</ListSubheader>
                                {representationAgeRangeOptions.map((name: string) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={representationSelectedOptions.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                        <Button
                                            sx={{
                                            display: representationSelectedOptions.indexOf(name) > -1 ? 'block' : 'none',
                                            }}
                                            variant="outlined"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setRepresentationSelectedOptions([name]);
                                                handleChangeRepresentation({target: { value: [name] }});
                                            }}
                                        >Somente</Button>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>


                        <FormControl sx={{ width: '100%', background: '#FFF', minHeight: '52.16px', borderRadius: '6px' }}>
                            <InputLabel id="multiple-select-group">Filtrar por grupo</InputLabel>
                            <Select
                                labelId="multiple-select-group"
                                id="multiple-select-group"
                                multiple
                                disabled={loading}
                                value={selectedGroupOptions}
                                onChange={handleChangeGroup}
                                onOpen={handleOpenGroupSelect}
                                IconComponent={FilterListIcon}
                                onClose={handleCloseGroupSelect}
                                input={<OutlinedInput label="Filtrar por grupo"
                                    color='primary'
                                    sx={{
                                        color: '#3C4043', //cor do texto dentro do input
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: selectedGroupOptions.length > 0 ? '#c9c9c9' : 'transparent', // Cor da borda
                                            borderRadius: '6px',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9',

                                        },
                                        '.MuiSelect-iconOpen': {
                                            transform: 'none !important',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor:  '#c9c9c9', // Cor da borda ao passar o mouse
                                        },

                                        borderRadius: '6px',
                                        border: 'none'


                                    }}

                                />}
                                renderValue={(selectedGroups) => {
                                    return selectedGroups
                                        .map(group => (selectGroupOptions.find((option: any) => option.value == group) as any)?.label as string ?? '')
                                        .join(', ')
                                }}
                            >
                                {selectGroupOptions.map((group: any) => (
                                    <MenuItem key={group.label} value={group.value}>
                                        <Checkbox checked={selectedGroupOptions.indexOf(group.value) > -1} />
                                        <ListItemText primary={group.label} />
                                        <Button
                                            sx={{
                                            display: selectedGroupOptions.indexOf(group.value) > -1 ? 'block' : 'none',
                                            }}
                                            variant="outlined"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setSelectedGroups([group.value]);
                                                handleChangeGroup({target: { value: [group.value] }});
                                            }}
                                        >Somente</Button>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </ContainerFilters>
                </ContainerSelectFilters>


                {/* Aqui vai o componente de Loading que é utilizado para quando os dados estão sendo carregados */}
                {loading ? (
                    // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: '300px' }}
                    >
                        <Spinner style={{ color: '#c9c9c9' }} />
                    </div>
                ) : (
                    // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor falso

                    <ContainerDashboard>
                        {/* Aqui vão os cards com os Gauges dos indicadores de IFOZ, Aderência, Engajamento e Fidelização */}
                        <ContainerPercentCard>
                            <ThemeProvider theme={theme}>

                                <PercentCard title={"IFOZ"} tooltipText='O Índice de Felicidade Organizacional Zenbox (IFOZ) ajuda a entender o quão saudável a sua cultura organizacional está para contribuir com o sucesso do seu negócio. Ele é constituído pela avaliação de 6 dimensões do comportamento humano, por meio de 24 Questões práticas sobre a Felicidade no Trabalho. Esse indicador é um coeficiente entre a média das respostas e a dispersão entre elas (o quão divergente foi a visão de cada respondente).' percentage={(((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? filteredData?.filteredIfoz : generalData.generalIfoz ? generalData.generalIfoz : null)} filterTag={((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? "Filtro" : null} />

                                <PercentCard title={"Aderência"} tooltipText='A Aderência representa a porcentagem de colaboradores respondentes em relação ao público total de colaboradores que foram convidados a refletir sobre a felicidade no trabalho até o fechamento do respectivo mês.' percentage={(((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? filteredData?.filteredIap : generalData.generalIap ? generalData.generalIap : null)} filterTag={((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? "Filtro" : null} />

                                <PercentCard title={"Engajamento"} tooltipText='O Engajamento representa a porcentagem média de vezes que cada colaborador respondeu no período de 3 meses consecutivos. O índice de Engajamento de um mês é resultado de um cálculo considerando também os dois meses anteriores ao respectivo mês. Por isso, é preciso ao menos 3 meses de medição para se ter o índice.' percentage={(((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? filteredData?.filteredIep : generalData.generalIep ? generalData.generalIep : null)} filterTag={((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? "Filtro" : null} />

                                <PercentCard title={"Fidelização"} tooltipText='A Fidelização representa um índice mais rico sobre a participação efetiva dos colaboradores no programa de felicidade organizacional. Consideramos aqui uma combinação de forma ponderada entre a Aderência que nos traz um dado quantitativo, e o Engajamento que nos traz um dado qualitativo desse envolvimento. Assim conseguimos avaliar o quanto o programa está conseguindo envolver de fato os colaboradores.' percentage={(((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? filteredData?.filteredIfcz : generalData.generalIfcz ? generalData.generalIfcz : null)} filterTag={((representationSelectedOptions?.length > 0) || (selectedGroupOptions?.length > 0)) ? "Filtro" : null} />

                            </ThemeProvider>
                        </ContainerPercentCard>

                        {/* Nesta row é exibido o gráfico de linha do IFOZ geral e se caso haja algum filtro, é exibida a linha do acumulado dos filtros */}
                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBodyOverflow style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <ContainerOverflowCharts>
                                            <MonthReportLineChart
                                                heightSize='335px'
                                                topPosition='6%'
                                                heightSizeResponsive='275px'
                                                topPositionResponsive='18.85%'
                                                chartId='generalIfozChart'
                                                generalEpData={generalData.generalEpDimension}
                                                generalEngData={generalData.generalEngDimension}
                                                generalRelData={generalData.generalRelDimension}
                                                generalSigData={generalData.generalSigDimension}
                                                generalReaData={generalData.generalReaDimension}
                                                generalVitData={generalData.generalVitDimension}

                                                filteredEpData={filteredData.filteredEpDimension}
                                                filteredEngData={filteredData.filteredEngDimension}
                                                filteredRelData={filteredData.filteredRelDimension}
                                                filteredSigData={filteredData.filteredSigDimension}
                                                filteredReaData={filteredData.filteredReaDimension}
                                                filteredVitData={filteredData.filteredVitDimension}

                                                />
                                        </ContainerOverflowCharts>
                                    </Card.Body>
                                </StyledCardBodyOverflow>
                            </Col>
                        </Row>
                        {/* Nas rows abaixo são exibidos os resultados detalhados de cada dimensão, apresentado o IFOZ da dimensão e de cada questão */}
                        <div>
                            <h4 style={{ color: '#3C4043', padding:'3em 0 0 0' }}>{'Por dimensão'}</h4>
                        </div>
                         

                        <StyledRowOverflow className='pb-2'  style={{overflow: 'auto', minHeight:'100%' }}>
                            <Col sm='12' style={{overflow: 'auto', minWidth: '600px', minHeight:'100%'}}>
                                
                                <Dimension type='Emoções Positivas' generalData={generalData.generalEpDimension} filteredData={filteredData.filteredEpDimension} key='0' dimensionKey='0' />
                            </Col>
                        </StyledRowOverflow>

                        <StyledRowOverflow className='pt-4' style={{overflow: 'auto', minHeight:'100%'}}>
                            <Col sm='12' style={{overflow: 'auto', minWidth: '600px', minHeight:'100%'}}>
                                <Dimension type='Engajamento' generalData={generalData.generalEngDimension} filteredData={filteredData.filteredEngDimension} dimensionKey='1' />
                            </Col>
                        </StyledRowOverflow>

                        <StyledRowOverflow className='pt-4' style={{overflow: 'auto', minHeight:'100%'}}>
                            <Col sm='12' style={{overflow: 'auto', minWidth: '600px', minHeight:'100%'}}>
                                <Dimension type='Relacionamento' generalData={generalData.generalRelDimension} filteredData={filteredData.filteredRelDimension} dimensionKey='2' />
                            </Col>
                        </StyledRowOverflow>

                        <StyledRowOverflow className='pt-4' style={{overflow: 'auto', minHeight:'100%'}} >
                            <Col sm='12' style={{overflow: 'auto', minWidth: '600px', minHeight:'100%'}}>
                                <Dimension type='Significado' generalData={generalData.generalSigDimension} filteredData={filteredData.filteredSigDimension} dimensionKey='3' />
                            </Col>
                        </StyledRowOverflow>

                        <StyledRowOverflow className='pt-4' style={{overflow: 'auto', minHeight:'100%'}}>
                            <Col sm='12' style={{overflow: 'auto', minWidth: '600px', minHeight:'100%'}}>
                                <Dimension type='Realização' generalData={generalData.generalReaDimension} filteredData={filteredData.filteredReaDimension} dimensionKey='4' />
                            </Col>
                        </StyledRowOverflow>

                        <StyledRowOverflow className='pt-4' style={{overflow: 'auto', minHeight:'100%'}}>
                            <Col sm='12' style={{overflow: 'auto', minWidth: '600px', minHeight:'100%'}}>
                                <Dimension type='Vitalidade' generalData={generalData.generalVitDimension} filteredData={filteredData.filteredVitDimension} dimensionKey='5' />
                            </Col>
                        </StyledRowOverflow>

                        <Row className="pt-4" style={{overflow: 'auto'}}>
                            <Accordion defaultActiveKey='0' style={{width:'100%', margin:'1em 0'  }}>
                                <CustomToggle eventKey='0'>
                                    <StyledHeaderDimension>
                                        <ContainerDimensionName>
                                            {!cardClicked ? < FaChevronUp  size={20} style={{ color: '#878B8E' }} /> : <FaChevronDown  size={20} style={{ color: '#878B8E' }} />}
                                            <StyledTitle>{'Qual foi a última vez que você se sentiu feliz no trabalho? E por quê?'}</StyledTitle>
                                        </ContainerDimensionName>
                                    </StyledHeaderDimension>
                                </CustomToggle>
                                <Accordion.Collapse style={{background:'none'}} eventKey='0'>
                                    {loadingDissertationsData ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loadingSexesData" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (<>{dissertationPageResults.data.length > 0 ?

                                            <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
                                                <ListTable>
                                                    <thead>
                                                        <tr>
                                                            <th style={{color: '#3C4043'}}>Mostrando {(dissertationPageResults.current_page === dissertationPageResults.total_pages) ? (((dissertationPageResults.current_page - 1) * 10) + 1) : ((dissertationPageResults.current_page * 10) - 9)}-{(dissertationPageResults.current_page === dissertationPageResults.total_pages) ? dissertationPageResults.total_items  : (dissertationPageResults.current_page * 10)} de {dissertationPageResults.total_items} respostas</th>
                                                            <StyledTh style={{color: '#3C4043'}} scope="col">Agrupamentos</StyledTh>
                                                            <StyledTh  style={{color: '#3C4043'}}scope="col">Data</StyledTh>
                                                        </tr>
                                                    </thead>
                                                    <Tbody>
                                                        {dissertationPageResults.data.map((item: any, index) => (
                                                            <StyledTr key={index} >
                                                                <StyledTdQualitativeAnswer style={{ borderBottom: "3px solid #F5F7F8"}} data-cell='Resposta'>{item?.answer}</StyledTdQualitativeAnswer>
                                                                <StyledTd style={{borderBottom: "3px solid #F5F7F8"}} data-cell='Grupo'>{item?.groups?.join(", ")}</StyledTd>
                                                                <StyledTd style={{borderBottom: "3px solid #F5F7F8"}} data-cell='Data de Envio'>{renderDate(item?.date_create)}</StyledTd>
                                                            </StyledTr>
                                                        ))}
                                                    </Tbody>
                                                </ListTable>
                                                <CustomPagination
                                                    data={dissertationPageResults}
                                                    handlePageChange={e => {
                                                        let representationsToFilter = representationSelectedOptions;

                                                        // Carrega os gêneros/sexos escolhidos no select de Filtro por Representatividade, mapeando pela label o valor numérico no molde que o back-end interpretará
                                                        let selectedSexes = representationsToFilter
                                                            ?.map(key => representationOptionsValues[key])
                                                            ?.filter(value => typeof value === 'number');

                                                        // Carrega as Faixas Etárias escolhidos no select de Filtro por Representatividade, mapeando pela label o valor numérico no molde que o back-end interpretará
                                                        let selectedAgeRanges = representationsToFilter
                                                            ?.map(key => representationOptionsValues[key])
                                                            ?.filter(value => typeof value === 'object')

                                                        // Carrega as escolhas de Sexos/Gêneros e Faixas Etárias já mapeadas para os valores no molde que o back-end interpretará somando aos IDs dos grupos escolhidos
                                                        let filters = {
                                                            sexes: selectedSexes ?? [],
                                                            ageRanges: selectedAgeRanges ?? [],
                                                            groups: selectedGroupOptions ?? []
                                                        }
                                                        loadDissertations(e, dateValue.split('-')[0], dateValue.split('-')[1], filters)
                                                    }}
                                                />
                                            </div>
                                            : (
    
                                                <ListTable>
                                                    <thead>
                                                        <StyledTr>
                                                            <th style={{width: "50%"}}>Mostrando 0 respostas</th>
                                                            <StyledTh style={{width: "25%"}} scope="col">Agrupamentos</StyledTh>
                                                            <StyledTh style={{width: "25%"}} scope="col">Data</StyledTh>
                                                        </StyledTr>
                                                    </thead>
                                                    {/* <Tbody>
                                                        <StyledTr>
                                                            <th style={{width: "50%"}}>Não há respondentes o suficiente para apresentar as respostas qualitativas</th>
                                                            <StyledTh style={{width: "25%"}} scope="col">-</StyledTh>
                                                            <StyledTh style={{width: "25%"}} scope="col">-</StyledTh>
                                                        </StyledTr>
                                                    </Tbody> */}
                                                    <tbody>
                                                        <StyledTr key={0} >
                                                            <StyledTdQualitativeAnswer style={{ borderBottom: "1px solid #D9D9D9" }} data-cell='Resposta'>Não há respondentes o suficiente para apresentar as respostas qualitativas</StyledTdQualitativeAnswer>
                                                            <StyledTd style={{ borderBottom: "1px solid #D9D9D9" }} data-cell='Grupo'>-</StyledTd>
                                                            <StyledTd style={{ borderBottom: "1px solid #D9D9D9" }} data-cell='Data de Envio'>-</StyledTd>
                                                        </StyledTr>
                                                    </tbody>
                                                </ListTable>
                                        )}</>)
                                    }
                                </Accordion.Collapse>
                            </Accordion>   
                        </Row>



                        {/* Aqui está sendo implementado um gráfico para comparação de gêneros utilizando o mesmo componente do gráfico de IFOZ geral */}

                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBodyOverflow style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <CustomAccordion isOpen={isOpenGenderGraph} setIsOpen={setIsOpenGenderGraph} title='Comparativo de cada questão - Por genêro' accordionKey='0'>

                                        <Card.Body style={{minHeight: '500px'}}>
                                            <div style={{ overflow: 'auto', minWidth: '950px' }}>
                                            {/* Aqui vai o componente de Loading que é utilizado para quando os dados estão sendo carregados */}
                                            {loadingSexesData ? (
                                                // Os elementos abaixo serão exibidos caso a variável "loadingSexesData" tenha um valor verdadeiro
                                                <div
                                                    className="d-flex align-items-center justify-content-center"
                                                    style={{ minHeight: '300px' }}
                                                >
                                                    <Spinner style={{ color: '#c9c9c9' }} />
                                                </div>
                                            ) : (                                            
                                                <MonthReportLineChart
                                                    heightSize='295px'
                                                    topPosition='14.5%'
                                                    heightSizeResponsive='275px'
                                                    topPositionResponsive='18.85%'
                                                    chartId='genderChart'
                                                    generalEpData={generalData.generalEpDimension}
                                                    generalEngData={generalData.generalEngDimension}
                                                    generalRelData={generalData.generalRelDimension}
                                                    generalSigData={generalData.generalSigDimension}
                                                    generalReaData={generalData.generalReaDimension}
                                                    generalVitData={generalData.generalVitDimension}
                                                    genderSeries={genderSeries} />
                                            )}
                                            </div>
                                        </Card.Body>
                                    </CustomAccordion>
                                </StyledCardBodyOverflow>
                            </Col>
                        </Row>


                        {/* Aqui está sendo implementado um gráfico para comparação de gêneros utilizando o mesmo componente do gráfico de IFOZ geral */}
                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBodyOverflow style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    {/* <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por faixa etária</h5>
                                    </StyledCardHeader> */}
                                    <CustomAccordion isOpen={isOpenAgeRangeGraph} setIsOpen={setIsOpenAgeRangeGraph} title='Comparativo de cada questão - Por faixa etária' accordionKey='1'>
                                        <Card.Body style={{minHeight: '500px'}}>
                                            <div style={{ overflow: 'auto', minWidth: '950px' }}>
                                                {/* Aqui vai o componente de Loading que é utilizado para quando os dados estão sendo carregados */}
                                                {loadingAgeRangesData ? (
                                                    // Os elementos abaixo serão exibidos caso a variável "loadingAgeRangesData" tenha um valor verdadeiro
                                                    <div
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{ minHeight: '300px' }}
                                                    >
                                                        <Spinner style={{ color: '#c9c9c9' }} />
                                                    </div>
                                                ) : (                                            
                                                    <MonthReportLineChart
                                                        heightSize='295px'
                                                        topPosition='15%'
                                                        heightSizeResponsive='275px'
                                                        topPositionResponsive='18.85%'
                                                        chartId='ageRangesChart'
                                                        generalEpData={generalData.generalEpDimension}
                                                        generalEngData={generalData.generalEngDimension}
                                                        generalRelData={generalData.generalRelDimension}
                                                        generalSigData={generalData.generalSigDimension}
                                                        generalReaData={generalData.generalReaDimension}
                                                        generalVitData={generalData.generalVitDimension}
                                                        ageRangesSeries={ageRangesSeries} />
                                                )}
                                            </div>
                                        </Card.Body>
                                    </CustomAccordion>
                                </StyledCardBodyOverflow>
                            </Col>
                        </Row>


                        {/* Aqui está sendo implementado um gráfico para comparação de gêneros utilizando o mesmo componente do gráfico de IFOZ geral */}
                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBodyOverflow style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <CustomAccordion title='Comparativo de cada questão - Por agrupamentos' isOpen={isOpenGroupGraph} setIsOpen={setIsOpenGroupGraph} accordionKey='2'>

                                        <Card.Body style={{minHeight: '500px'}}>
                                            <div style={{ overflow: 'auto', minWidth: '950px' }}>
                                            {/* Aqui vai o componente de Loading que é utilizado para quando os dados estão sendo carregados */}
                                            {loadingGroupsData ? (
                                                // Os elementos abaixo serão exibidos caso a variável "loadingGroupsData" tenha um valor verdadeiro
                                                <div
                                                    className="d-flex align-items-center justify-content-center"
                                                    style={{ minHeight: '300px' }}
                                                >
                                                    <Spinner style={{ color: '#c9c9c9' }} />
                                                </div>
                                            ) : (                                            
                                                <MonthReportLineChart
                                                    heightSize='335px'
                                                    topPosition='6%'
                                                    heightSizeResponsive='275px'
                                                    topPositionResponsive='18.85%'
                                                    chartId='groupsChart'
                                                    generalEpData={generalData.generalEpDimension}
                                                    generalEngData={generalData.generalEngDimension}
                                                    generalRelData={generalData.generalRelDimension}
                                                    generalSigData={generalData.generalSigDimension}
                                                    generalReaData={generalData.generalReaDimension}
                                                    generalVitData={generalData.generalVitDimension}
                                                    groupsSeries={groupsSeries} />
                                            )}
                                            </div>
                                        </Card.Body>

                                    </CustomAccordion>
                                </StyledCardBodyOverflow>
                            </Col>
                        </Row>


                        {/* GRÁFICOS DESATIVADOS */}
                        {/* <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por gênero</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <div style={{ overflow: 'auto', minWidth: '800px' }}>
                                            <LineChartGender />
                                        </div>
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row>


                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por faixa etária</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <div style={{ overflow: 'auto', minWidth: '800px' }}>
                                            <LineChartAge />
                                        </div>
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row>


                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por agrupamento</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <div style={{ overflow: 'auto', minWidth: '800px' }}>
                                            <LineChartGroup />
                                        </div>
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row> */}
                        {/* GRÁFICOS DESATIVADOS */}



                        {/* 
                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043',  }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Matriz de Correlação</h5>
                                    </StyledCardHeader>

                                    <Card.Body  style={{overflowX:'auto'}}>
                                        <div style={{overflowX:'auto', minWidth: '800px'}}>
                                            <CorrelationMatrix />
                                        </div>     

                                       
                                    </Card.Body>
                                    <Card.Footer style={{background: '#FFF', borderRadius: '0 0 1em 1em', borderTop: '2px solid #F5F7F8' }}>
                                        <ContainerCardMatrix>
                                            <Paper sx={{background: '#F5F7F8'}} style={{ padding: '1em', borderRadius: '1em'}}>
                                                <div style={{display: 'flex', alignItems: 'center', gap: '1em',  marginBottom:'1em'}}>
                                                    <QuestionAnswerOutlinedIcon />
                                                    <h5 style={{margin:'0'}}>Perguntas</h5>
                                                </div>
                                                <div>
                                                    <p>As questões assinaladas em <span style={{color: '#FD4659'}}>vermelho</span> são as que apresentam avaliação mais baixa em fevereiro</p>
                                                    <ul>
                                                        <li><strong>D1.4</strong> - Sinto que a empresa se esforça para aumentar meu nivel de bem-estar</li>
                                                        <li><strong>D6.2</strong> - Existe estímulo para me alimentar bem, com refeições saudáveis para manter a saúde física e mental</li>
                                                        <li><strong>D6.3</strong> - Recebo orientação para aproveitar melhor o meu tempo de lazer</li>
                                                        <li><strong>D6.4</strong> - Sou orientado para me movimentar bem fisicamente, por meio de exercícios e atividades físicas em geral</li>
                                                    </ul>
                                                </div>
                                            </Paper>

                                            <Paper sx={{background: '#F5F7F8'}} style={{ padding: '1em', borderRadius: '1em'}}>
                                                
                                                <div style={{display: 'flex', alignItems: 'center', gap: '1em', marginBottom:'1em'}}>
                                                    <TroubleshootOutlinedIcon/>
                                                    <h5 style={{margin:'0'}}>Análise</h5>
                                                </div>
                                                <div>
                                                    <ul>
                                                        <li style={{margin:'0'}}>A questão D6.2 além de impactar a D6.4 que é também de Vitalidade, ela impacta a questão D1.4 que é de Emoções positivas </li>
                                                    </ul>
                                                </div>
                                            </Paper>
                                         
                                        </ContainerCardMatrix>
                                    </Card.Footer>
                                        
                                </StyledCardBody>
                            </Col>
                        </Row> */}


                    </ContainerDashboard>




                )}
            </Container>
        </>

    );
};

export default HappinessReport;